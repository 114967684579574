import { Item, Step6ApiRes } from '@/components/MultiStepForm/types';
import ReactGA from 'react-ga4';

const params = new URLSearchParams(window.location.search);

//const getPathWithHost = () => window.location.host + window.location.pathname; //@ts-ignore
const getHookUrl = () => params.get('hookUrl') || '';

type ElBridgeItem = {
  enumber?: string;
  quantity?: string;
  SUPPLIER_ID_DUNS?: string;
  SUPPLIER_ID_GLN?: string;
  MANUFACTURER_PID: string;
  QUANTITY: string;
  REFNUMBER_CONFIG?: string;
  DESCRIPTION_SHORT?: string;
  MANUFACTURER_TYPE_DESCR?: string;
  PRICE_AMOUNT?: string;
  CURRENCY?: string;
  PRICE_QUANTITY?: string;
  ['UDX.EDXF.DISCOUNT_GROUP_MANUFACTURER']?: string;
  ORDER_UNIT?: string;
  INTERNATIONAL_PID?: string | null;
};

const ConfiguratorDefinitions: {
  wid: string;
  identifier: string;
  distributor: boolean;
  endpointHash: string;
  displayName: string;
  extendedElbridge: boolean;
  redirectUrl: string | null;
  blankAddToCart: boolean;
  elbridgeItemMapper: (configurationItem: Item) => ElBridgeItem;
  activator: () => boolean;
}[] = [
  // {
  //   wid: 'oosterberg_nl',
  //   identifier: 'oosterberg-nl', // .sesmartconfig.com
  //   distributor: true,
  //   endpointHash:
  //     'ca6724eab9d0b613a57a97eafba85dc6a210b39fb90c866d571e20808cda9fdc',
  //   displayName: 'Oosterberg',
  //   extendedElbridge: false,
  //   redirectUrl: null,
  //   blankAddToCart: false,
  //   elbridgeItemMapper: (configurationItem) => {
  //     let item: ElBridgeItem = {
  //       SUPPLIER_ID_GLN: '8712423008878',
  //       MANUFACTURER_PID: configurationItem.cr,
  //       QUANTITY: String(configurationItem.adjustedQuantity),
  //       REFNUMBER_CONFIG: configurationItem.cr,
  //       DESCRIPTION_SHORT: configurationItem.crDescription,
  //       // "PRICE_AMOUNT": "1.23",
  //       // "CURRENCY": "EUR",
  //     };
  //     var configuratorDefinition = getConfiguratorDefinition();

  //     if (configuratorDefinition?.extendedElbridge) {
  //       // (item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup),
  //       //   (item.PRICE_AMOUNT = configurationItem.price
  //       //     .split('€ ')[1]
  //       //     .replace(',', '.'));
  //       item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup;
  //       item.PRICE_AMOUNT = configurationItem.price
  //         .split('€ ')[1]
  //         .replace(',', '.');
  //       item.CURRENCY = 'EUR';
  //       item.PRICE_QUANTITY = '1';
  //       item['UDX.EDXF.DISCOUNT_GROUP_MANUFACTURER'] =
  //         configurationItem.priceGroup;
  //       item.ORDER_UNIT = 'C62';
  //     }

  //     return item;
  //   },
  //   activator: () => window.location.host === 'oosterberg-nl.sesmartconfig.com',
  // },
  // {
  //   wid: 'nextuser_com',
  //   identifier: 'technischeuni', // .sesmartconfig.com
  //   distributor: false,
  //   endpointHash:
  //     'cb77ea97307947e5ad756670f51ea07c8eb33f0f7352cc6df44365a4990242df',
  //   displayName: '',
  //   extendedElbridge: false,
  //   redirectUrl: null,
  //   blankAddToCart: false,
  //   elbridgeItemMapper: function (configurationItem) {
  //     let item: ElBridgeItem = {
  //       SUPPLIER_ID_GLN: '8712423008878',
  //       MANUFACTURER_PID: configurationItem.cr,
  //       QUANTITY: String(configurationItem.adjustedQuantity),
  //       REFNUMBER_CONFIG: configurationItem.cr,
  //       DESCRIPTION_SHORT: configurationItem.crDescription,
  //       // "PRICE_AMOUNT": "1.23",
  //       // "CURRENCY": "EUR",
  //     };
  //     var configuratorDefinition = getConfiguratorDefinition();

  //     if (configuratorDefinition?.extendedElbridge) {
  //       // (item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup),
  //       //   (item.PRICE_AMOUNT = configurationItem.price
  //       //     .split('€ ')[1]
  //       //     .replace(',', '.'));
  //       item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup;
  //       item.PRICE_AMOUNT = configurationItem.price
  //         .split('€ ')[1]
  //         .replace(',', '.');
  //       item.CURRENCY = 'EUR';
  //       item.PRICE_QUANTITY = '1';
  //       item['UDX.EDXF.DISCOUNT_GROUP_MANUFACTURER'] =
  //         configurationItem.priceGroup;
  //       item.ORDER_UNIT = 'C62';
  //     }

  //     return item;
  //   },
  //   activator: () => window.location.host === 'nextuser.com',
  // },  
  // {
  //   wid: 'se_nl',
  //   identifier: 'oosterberg-nl', // .sesmartconfig.com
  //   distributor: false,
  //   endpointHash:
  //     'f4586ad10ac6b76146c1bf0e0755a8539d313caaa73d777c2eb27b8f41b17b23',
  //   displayName: '',
  //   extendedElbridge: false,
  //   redirectUrl: null,
  //   blankAddToCart: false,
  //   elbridgeItemMapper: function (configurationItem) {
  //     let item: ElBridgeItem = {
  //       SUPPLIER_ID_GLN: '8712423008878',
  //       MANUFACTURER_PID: configurationItem.cr,
  //       QUANTITY: String(configurationItem.adjustedQuantity),
  //       REFNUMBER_CONFIG: configurationItem.cr,
  //       DESCRIPTION_SHORT: configurationItem.crDescription,
  //       // "PRICE_AMOUNT": "1.23",
  //       // "CURRENCY": "EUR",
  //     };
  //     var configuratorDefinition = getConfiguratorDefinition();

  //     if (configuratorDefinition?.extendedElbridge) {
  //       // (item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup),
  //       //   (item.PRICE_AMOUNT = configurationItem.price
  //       //     .split('€ ')[1]
  //       //     .replace(',', '.'));
  //       item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup;
  //       item.PRICE_AMOUNT = configurationItem.price
  //         .split('€ ')[1]
  //         .replace(',', '.');
  //       item.CURRENCY = 'EUR';
  //       item.PRICE_QUANTITY = '1';
  //       item['UDX.EDXF.DISCOUNT_GROUP_MANUFACTURER'] =
  //         configurationItem.priceGroup;
  //       item.ORDER_UNIT = 'C62';
  //     }

  //     return item;
  //   },
  //   activator: () => {  console.log(window.location.host); return window.location.host === 'localhost:3000'},
  // },
  // {
  //   wid: 'se_nl',
  //   identifier: 'technischeuni', // .sesmartconfig.com
  //   distributor: false,
  //   endpointHash:
  //     '86f56a51090a64e72882fc6f54eeb6326f77e56700c2584aacd181607d96932c',
  //   displayName: '',
  //   extendedElbridge: false,
  //   redirectUrl: null,
  //   blankAddToCart: false,
  //   elbridgeItemMapper: function (configurationItem) {
  //     let item: ElBridgeItem = {
  //       SUPPLIER_ID_GLN: '8712423008878',
  //       MANUFACTURER_PID: configurationItem.cr,
  //       QUANTITY: String(configurationItem.adjustedQuantity),
  //       REFNUMBER_CONFIG: configurationItem.cr,
  //       DESCRIPTION_SHORT: configurationItem.crDescription,
  //       // "PRICE_AMOUNT": "1.23",
  //       // "CURRENCY": "EUR",
  //     };
  //     var configuratorDefinition = getConfiguratorDefinition();

  //     if (configuratorDefinition?.extendedElbridge) {
  //       // (item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup),
  //       //   (item.PRICE_AMOUNT = configurationItem.price
  //       //     .split('€ ')[1]
  //       //     .replace(',', '.'));
  //       item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup;
  //       item.PRICE_AMOUNT = configurationItem.price
  //         .split('€ ')[1]
  //         .replace(',', '.');
  //       item.CURRENCY = 'EUR';
  //       item.PRICE_QUANTITY = '1';
  //       item['UDX.EDXF.DISCOUNT_GROUP_MANUFACTURER'] =
  //         configurationItem.priceGroup;
  //       item.ORDER_UNIT = 'C62';
  //     }

  //     return item;
  //   },
  //   activator: () => getPathWithHost() === 'schneider.nextuser.com/index.html',
  // },
  {
    wid: 'internal_tests',
    identifier: 'internal_tests', // .sesmartconfig.com
    distributor: false,
    endpointHash:
      'f4586ad10ac6b76146c1bf0e0755a8539d313caaa73d777c2eb27b8f41b17b23',
    displayName: '',
    extendedElbridge: false,
    redirectUrl: null,
    blankAddToCart: false,
    elbridgeItemMapper: function (configurationItem) {
      let item: ElBridgeItem = {
        SUPPLIER_ID_GLN: '8712423008878',
        MANUFACTURER_PID: configurationItem.cr,
        QUANTITY: String(configurationItem.adjustedQuantity) + ".00",
        REFNUMBER_CONFIG: configurationItem.cr,
        DESCRIPTION_SHORT: configurationItem.crDescription,
        ORDER_UNIT: "C62",
        // "PRICE_AMOUNT": "1.23",
        // "CURRENCY": "EUR",
      };
      var configuratorDefinition = getConfiguratorDefinition();

      if (configuratorDefinition?.extendedElbridge) {
        // (item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup),
        //   (item.PRICE_AMOUNT = configurationItem.price
        //     .split('€ ')[1]
        //     .replace(',', '.'));
        item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup;
        item.PRICE_AMOUNT = configurationItem.price
          .split('€ ')[1]
          .replace(',', '.');
        item.CURRENCY = 'EUR';
        item.PRICE_QUANTITY = '1';
        item['UDX.EDXF.DISCOUNT_GROUP_MANUFACTURER'] =
          configurationItem.priceGroup;
        item.ORDER_UNIT = 'C62';
      }

      return item;
    },
    // activator: () => {  return window.location.host === 'localhost:3000' || window.location.host === 'configurator.wibe-group.com'},
    activator: () => {  return params.get('distributor') === 'internal_tests'},
  },
  {
    wid: 'rexelnl',
    identifier: 'rexelnl', // .sesmartconfig.com
    distributor: true,
    endpointHash:
      '6472d5c1677640d8b00fd0e5c1357c2b5157f81193012b063134bc027ddc65fe',
    displayName: 'Rexel',
    extendedElbridge: true,
    redirectUrl: null,
    blankAddToCart: false,
    elbridgeItemMapper: function (configurationItem) {
      let item: ElBridgeItem = {
        SUPPLIER_ID_DUNS: '331716014',
        MANUFACTURER_PID: configurationItem.cr,
        QUANTITY: String(
          configurationItem.adjustedQuantity *
            configurationItem.rexelQuantityMultiplier
        ),
        INTERNATIONAL_PID: configurationItem.ean,
        MANUFACTURER_TYPE_DESCR: configurationItem.crDescription,
        ORDER_UNIT: configurationItem.contentUnits,
        // "PRICE_AMOUNT": "1.23",
        // "CURRENCY": "EUR",
      };

      return item;
    },
      activator: () => {  return params.get('distributor') === 'rexelnl'},
    },
  {
    wid: 'technischeunie_nl',
    identifier: 'technischeuni', // .sesmartconfig.com
    distributor: true,
    endpointHash:
      '86f56a51090a64e72882fc6f54eeb6326f77e56700c2584aacd181607d96932c',
    displayName: 'Technische Unie',
    extendedElbridge: false,
    redirectUrl:
      'https://www.technischeunie.nl/redirect/elbridgeRedirect.jsp?toolId=lt14600003',
    blankAddToCart: true,
    elbridgeItemMapper: function (configurationItem) {
      let item: ElBridgeItem = {
        SUPPLIER_ID_GLN: '8712423008878',
        MANUFACTURER_PID: configurationItem.cr,
        QUANTITY: String(configurationItem.adjustedQuantity),
        REFNUMBER_CONFIG: configurationItem.cr,
        DESCRIPTION_SHORT: configurationItem.crDescription,
        ORDER_UNIT: 'C62',
        // "PRICE_AMOUNT": "1.23",
        // "CURRENCY": "EUR",
      };
      var configuratorDefinition = getConfiguratorDefinition();

  //     // var cookie = _iam.lib.storage.cookie.get('distributor_type');

      return item;
    },
    activator: () => getHookUrl().indexOf('www.technischeunie.nl') >= 0 
  },
  // {
  //   wid: 'itek',
  //   identifier: 'oosterberg_nl', // .sesmartconfig.com
  //   distributor: true,
  //   endpointHash:
  //     'ca6724eab9d0b613a57a97eafba85dc6a210b39fb90c866d571e20808cda9fdc',
  //   displayName: 'Oosterberg',
  //   extendedElbridge: true,
  //   redirectUrl: null,
  //   blankAddToCart: true,
  //   elbridgeItemMapper: function (configurationItem) {
  //     let item: ElBridgeItem = {
  //       SUPPLIER_ID_GLN: '8712423008878',
  //       MANUFACTURER_PID: configurationItem.cr,
  //       QUANTITY: String(configurationItem.adjustedQuantity),
  //       REFNUMBER_CONFIG: configurationItem.cr,
  //       DESCRIPTION_SHORT: configurationItem.crDescription,
  //       // "PRICE_AMOUNT": "1.23",
  //       // "CURRENCY": "EUR",
  //     };
  //     var configuratorDefinition = getConfiguratorDefinition();

  //     if (configuratorDefinition?.extendedElbridge) {
  //       // (item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup),
  //       //   (item.PRICE_AMOUNT = configurationItem.price
  //       //     .split('€ ')[1]
  //       //     .replace(',', '.'));
  //       item.MANUFACTURER_TYPE_DESCR = configurationItem.priceGroup;
  //       item.PRICE_AMOUNT = configurationItem.price
  //         .split('€ ')[1]
  //         .replace(',', '.');
  //       item.CURRENCY = 'EUR';
  //       item.PRICE_QUANTITY = '1';
  //       item['UDX.EDXF.DISCOUNT_GROUP_MANUFACTURER'] =
  //         configurationItem.priceGroup;
  //       item.ORDER_UNIT = 'C62';
  //     }

  //     return item;
  //   },
  //   activator: () => getHookUrl().indexOf('itek.de') >= 0,
  // },
];

const getConfiguratorDefinition = () => {
  for (let configuratorDefinition of ConfiguratorDefinitions) {
    if (configuratorDefinition.activator()) return configuratorDefinition;
  }

  return ConfiguratorDefinitions[0];
};

const generateResult = <T extends boolean>(overview: Step6ApiRes, isEs: T) => {
  // let result = {
  //   CONFIGURATION_URL: 'nextuser.com',
  //   ITEM: [] as ElBridgeItem[],
  // };
  let addedItems: ElBridgeItem[] = [];

  const { subprojects, configurations, pendants } = overview;
  const allConfigurations = [...subprojects, ...configurations, ...pendants];
  const configuratorDefinition = getConfiguratorDefinition();

  if (configuratorDefinition) {
    for (let configuration of allConfigurations) {
      for (let configurationItem of configuration.items) {
        const elBridgeItem =
          configuratorDefinition.elbridgeItemMapper(configurationItem);

        const addedItem = addedItems.find(
          (addedItem) => addedItem.MANUFACTURER_PID === configurationItem.cr
        );

        if (addedItem) {
          const updatedQuantity = String(
            parseInt(addedItem.QUANTITY, 10) +
              configurationItem.adjustedQuantity
          );
          // const index = addedItems.findIndex(
          //   (i) => i.MANUFACTURER_PID === addedItem.MANUFACTURER_PID
          // );

          addedItem.QUANTITY = updatedQuantity + ".00";
          // result.ITEM[index].QUANTITY = updatedQuantity;
        } else {
          addedItems.push(elBridgeItem);
          // result.ITEM.push(elBridgeItem);
        }
      }
    }

    if (isEs)
      return addedItems.map((ai) => ({
        enumber: ai.MANUFACTURER_PID,
        quantity: ai.QUANTITY,
      }));
    else return addedItems;
  }
};

const createHiddenInput = (name: string, value: string | null) => {
  const input = document.createElement('input');

  input.setAttribute('name', name);
  input.setAttribute('value', value || '');
  input.setAttribute('type', 'hidden');

  return input;
};

const submitFormNewTab = (data: { result: ElBridgeItem[] }) => {
  const configuratorDefinition = getConfiguratorDefinition(); // @ts-ignore

  if (configuratorDefinition) {
    const $form = document.createElement('form');

    $form.setAttribute('method', 'post');
    $form.method = 'post';
    $form.setAttribute('name', 'start');
    $form.name = 'start';

    if (configuratorDefinition.blankAddToCart) {
      $form.target = '_blank';
    }

    const hookUrlDecoded = (params.get('hookUrl') || '').replace(/&amp;/g, '&');

    $form.setAttribute('action', hookUrlDecoded);
    $form.action = hookUrlDecoded;
    $form.setAttribute('enctype', 'multipart/form-data');
    $form.appendChild(createHiddenInput('version', params.get('version')));

    if (configuratorDefinition?.extendedElbridge) {
      $form.appendChild(createHiddenInput('language', params.get('language')));
      $form.appendChild(createHiddenInput('country', params.get('country')));
    }

    const resultData = JSON.stringify(data.result);
    $form.appendChild(createHiddenInput('result', resultData));

    document.body.appendChild($form);
    const form = document.querySelector('form');

    form?.submit();

    if (configuratorDefinition.redirectUrl) {
      window.location.href = configuratorDefinition.redirectUrl;
    }
  }
};

const getRandomTransactionId = () => {
  const MaxNumber = 100000;
  const tid = Math.floor(Math.random() * MaxNumber);
  const tidString = `T_${tid}-${Date.now()}`;
  return tidString;
};

const addToCartViaElbridge = (overview: Step6ApiRes) => {
  if (overview) {
    const isEs = window.location.href.includes('distributor=es');
    const result = generateResult(overview, isEs);

    if (result) {
      if (isEs) {
        const cartData = { products: result };
        console.log(cartData);
        window.postMessage(cartData, '*');
        window?.parent?.postMessage(cartData, '*');

        const randomTID = getRandomTransactionId();

        let totalPriceStr: string;
        if (overview.projectSummary.totalPrice.indexOf(',') >= 0) {
          totalPriceStr = overview.projectSummary.totalPrice.split(',')[0];
        } else {
          totalPriceStr = overview.projectSummary.totalPrice || '';
        }

        totalPriceStr = totalPriceStr.replace(/[^0-9.-]+/g, '');
        totalPriceStr = totalPriceStr.split('.').join('');

        let totalPriceNum: number;
        if (totalPriceStr.indexOf('.') >= 0) {
          totalPriceNum = parseFloat(totalPriceStr.split('.').join(''));
        } else {
          totalPriceNum = parseFloat(totalPriceStr);
        }

        if (cartData.products.length <= 0) {
          return;
        }

        const productsArray = cartData.products.map((p) => ({
          item_id: p.enumber,
          quantity: Number(p.quantity),
        }));

        ReactGA.event({
          category: 'CableTracker.purchase',
          action: 'complete',
          label: totalPriceStr,
        });

        ReactGA.event('purchase', {
          transaction_id: randomTID,
          value: totalPriceNum,
          currency: 'EUR',
          items: productsArray,
        });
      } else submitFormNewTab({ result: result as ElBridgeItem[] });
    }
  }
};

export default addToCartViaElbridge;
