import './styles.css';
import { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { VscCircle, VscCircleFilled } from 'react-icons/vsc';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { ApiHashContext } from 'contexts/apiHashContext';
import { useMyContext, addToCartViaElbridge } from 'helpers';
import { FileAdd, PDF, XLSX, FolderFull } from 'assets/icons/file';
import { Accessories } from 'assets/icons/misc';
import { useModalState } from 'hooks';
import MyProjectsModal from '../../../MyProjectsModal';
import UpsertConfigurationModal from '../../../MyProjectsModal/components/UpsertConfigurationModal';
import ExportProjectModal from '../ExportProjectModal';
import AccessoriesModal from '../AccessoriesModal';
import { Loader, PDFModal, Tooltip } from 'shared/components';
import { Configuration } from 'shared/types';
import { ProjectsContext } from 'contexts/projectsContext';

import type { Step6ApiRes } from '../../types';
import { API_HOST } from 'shared/constants';

const classificationsColors: Record<string, string> = {
  C1: '#f9e4f2',
  C2: '#fcdfb9',
  C3: '#a6f5f7',
  C4: '#b8ffe9',
  C5: '#cdd4f9',
  C6: '#c6e0de',
};

const configMapper = (configurations: Configuration<5>[]) =>
  configurations.map((c, i) => ({
    accessories: (c.data.accessories || []).map((a) => ({
      cr: a.cr,
      quantity: a.quantity,
    })),
    bends: (c.data.bends || []).map((b) => ({
      cr: b.cr,
      quantity: b.quantity,
      coverQuantity: b.coverQuantity,
      selectedName: b.name,
    })),
    configurationId: i,
    configurationName: c.name,
    coverLength: c.data.lidLength,
    dataChannelCr: c.data.dataGutter,
    selectedCouplerCr: c.data.coupler,
    selectedDividerCr: c.data.divider,
    selectedSuspensionDistance: c.data.suspensionDistance,
    suspensionCr: c.data.suspension,
    trayCr: c.data.gutter,
    trayLength: c.data.length,
    wallDividerLength: c.data.dividerLength,
    selectedCeilingAttachment: c.data.celingAttachment,
  }));

const getStep6Data = async (apiHash: string, step5Payload: any) => {
  try {
    const { data } = await axios.post<Step6ApiRes>(
      `${API_HOST}/wibe/step6/${apiHash}`,
      step5Payload
    );

    return data;
  } catch (err) {
    console.error(err);
  }
};

const Overview = () => {
  const { t } = useTranslation();
  const { currentProject, previousStep, setStep } = useMyContext<5>();
  const { isValidEnumCondition, getLanguageParamKey } =
    useContext(ProjectsContext);
  const { apiHash } = useContext(ApiHashContext);
  const [pdfLink, setPdfLink] = useState('');
  const [trayId, setTrayId] = useState('');
  const [isOpen, openModal, closeModal] = useModalState({
    projects: false,
    config: false,
    pdf: false,
    xlsx: false,
    accessories: false,
    recommended: false,
  });
  const { isLoading, error, data, refetch } = useQuery(
    'step6Data',
    () => getStep6Data(apiHash, step6Payload),
    { refetchOnWindowFocus: false }
  );

  const showAddToCart =
    window.location.href.includes('distributor=es') ||
    window.location.href.includes('hookUrl');

  const step6Payload = {
    subprojects: currentProject.subProjects.map((s) => ({
      subprojectId: s.id,
      subprojectName: s.name,
      configurations: configMapper(s.configurations),
    })),
    projectName: currentProject.name,
    pendants: currentProject.pendants.map((s) => ({
      pendantCr: s.configurations[0].data.pendant,
      pendantId: s.id,
      pendantName: s.name,
      configurations: configMapper(s.configurations),
      selectedSuspensionDistance:
        s.configurations[0].data.pendantSupportDistance?.replace(',', '.'),
    })),
    language: 'en',
    configurations: configMapper(currentProject.configurations),
  };

  const toggleRecommended = () => {
    isOpen.recommended ? closeModal('recommended') : openModal('recommended');
  };

  const refetchCallback = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    refetchCallback();
  }, [refetchCallback]);

  const isDistributor = window.location.href.indexOf('wibe') < 0;

  if (isLoading) return <Loader />;
  if (error || !data)
    return (
      <p className="center-x api-error">{t('fetching.overview.error.msg')}</p>
    );

  return (
    <div className="overview">
      <>
        <div>
          <h1>
            {t('java.export.projectName')} {currentProject.name}
            <button
              className="my-projects-btn"
              onClick={() => {
                openModal('projects');
              }}
            >
              <FolderFull />
              {t('global.my.projects')}
            </button>
          </h1>
          <div className="exports-container">
            <button className="center-xy" onClick={() => openModal('config')}>
              <FileAdd />
              {t('project.review.add.configuration')}
            </button>
            <button className="center-xy" onClick={() => openModal('pdf')}>
              <PDF />
              {t('project.review.export.project.pdf')}
            </button>
            <button className="center-xy" onClick={() => openModal('xlsx')}>
              <XLSX />
              {t('project.review.export.project.xlsx')}
            </button>
          </div>
          <div className="project-details">
            <span>
              {t('project.details.weight')}: {data.projectSummary.totalWeight}
            </span>
            <span>
              {t('project.details.amount')}: {data.projectSummary.totalQuantity}
            </span>
            {/* <span>
              {t('project.details.gross.price')}:{' '}
              {data.projectSummary.totalPrice}
            </span> */}
          </div>

          {[...data.configurations, ...data.subprojects, ...data.pendants].map(
            (c, i) => {
              const isSubProject = !('configurationId' in c);
              const subProjectType =
                'subprojectId' in c
                  ? t('summary.subproject.name.header')
                  : t('global.pendant.title');

              return (
                <div className="config-overview-container" key={`option-${i}`}>
                  <h2>
                    {t('summary.subproject.name.header')}:{' '}
                    {!isSubProject
                      ? c.configurationName
                      : 'subprojectId' in c
                      ? c.subprojectName
                      : c.pendantName}
                  </h2>
                  <div className="config-overview">
                    <div className="config-actions">
                      <strong>
                        {!isSubProject
                          ? t('configuration.title')
                          : subProjectType}{' '}
                        {t('main.content.overview')}
                      </strong>
                      <span
                        className="center-xy"
                        onClick={() => {
                          setTrayId(
                            currentProject.configurations[i]?.data.gutter
                          );
                          openModal('accessories');
                        }}
                      >
                        <Accessories />
                        {t('review.config.add.accesories')}
                      </span>
                      <span
                        className="center-xy"
                        onClick={() => openModal('pdf')}
                      >
                        <PDF />
                        {t('global.export.configuration')}.pdf
                      </span>
                      <span
                        className="center-xy"
                        onClick={() => openModal('xlsx')}
                      >
                        <XLSX />
                        {t('global.export.configuration')}.xlsx
                      </span>
                    </div>

                    {!isSubProject && (
                      <div
                        className="material-overview"
                        style={{
                          backgroundColor:
                            classificationsColors[
                              currentProject.configurations[i]?.data.material
                            ],
                        }}
                      >
                        <strong>{t('main.content.material')}</strong>
                        <div>
                          <div className="text-secondary">
                            {t('global.surface.treatment')}
                          </div>
                          <div>
                            {currentProject.configurations[i]?.data.gutterObj
                              ?.surfaceTreatment || 'N/A'}
                          </div>
                        </div>
                        <div>
                          <div className="text-secondary">
                            {t('configuration.material.classification')}
                          </div>
                          <div>
                            {currentProject.configurations[i]?.data.material ||
                              'N/A'}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="products-overview">
                      <strong>{t('summary.config.products.title')}</strong>
                      <table cellSpacing="0">
                        <thead>
                          <tr>
                            <th></th>
                            <th>{t('summary.config.products.image')}</th>
                            <th>{t('summary.config.products.desc')}</th>
                            {isValidEnumCondition() ? (
                              <th>eNumber</th>
                            ) : (
                              <th>{t('summary.config.products.stago.cr')}</th>
                            )}
                            <th>{t('summary.config.products.custom.qty')}</th>
                            <th>
                              {t('summary.config.products.weight.per.unit')}
                            </th>
                            {/* <th>{t('summary.config.products.price.per.unit')}</th> */}
                            <th>{t('summary.config.products.datasheet')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {c.items.map((i) => ( i.adjustedQuantity > 0 &&
                            <tr
                              key={`item-${i.cr}-${i.distributorCr}`}
                              style={{
                                background:
                                  i.level === 'level2' ? '#eaeaea61' : '',
                              }}
                            >
                              <Tooltip
                                delay={0}
                                relativeToParent
                                position={{
                                  top: 35,
                                  left: 35,
                                }}
                                content={
                                  i.level === 'level1'
                                    ? t('backend.level.desc.your.selection') ||
                                      'Your selection'
                                    : t(
                                        'backend.level.desc.req.for.installation.auto.added'
                                      ) ||
                                      'Required for installation\n(automatically added)'
                                }
                              >
                                <td>
                                  {i.level === 'level1' ? (
                                    <VscCircleFilled
                                      size={30}
                                      color="var(--accent-color)"
                                    />
                                  ) : (
                                    <VscCircle
                                      size={30}
                                      color="var(--accent-color)"
                                    />
                                  )}
                                </td>
                              </Tooltip>
                              <td>
                                <img
                                  src={i.imageUrl}
                                  height={30}
                                  alt={'item-img'}
                                />
                              </td>
                              <td>{i.crDescription}</td>
                              {isValidEnumCondition() ? (
                                <th>{i.enumber}</th>
                              ) : (
                                <td>{i.cr}</td>
                              )}
                              <td>
                                {i.adjustedQuantity}
                                {i.length ? ' x ' + i.length : i.packaging ? ' ' + i.packaging : ''}
                              </td>
                              <td>{i.weight}</td>
                              {/* <td>{i.price}</td> */}
                              <td
                              // onClick={() => setPdfLink(i.documentationLink)}
                              >
                                <a
                                  href={i.documentationLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t('summary.row.view.details')}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>{t('summary.config.products.total')}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{c.summary.totalQuantity}</td>
                            <td>{c.summary.totalWeight}</td>
                            <td></td>
                            {/* <td>{c.summary.totalPrice}</td> */}
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
        <MyProjectsModal
          isOpen={isOpen.projects}
          onClose={() => closeModal('projects')}
        />
        <UpsertConfigurationModal
          action="create"
          isOpen={isOpen.config}
          onClose={() => closeModal('config')}
          project={currentProject.id}
        />
        <ExportProjectModal
          name={currentProject.name}
          noDistributor={!isDistributor}
          format="pdf"
          isOpen={isOpen.pdf}
          onClose={() => closeModal('pdf')}
          payload={{
            ...step6Payload,
            projectCode: currentProject.remoteId,
            language: getLanguageParamKey(),
          }}
        />
        <ExportProjectModal
          name={currentProject.name}
          noDistributor={!isDistributor}
          format="xlsx"
          isOpen={isOpen.xlsx}
          onClose={() => closeModal('xlsx')}
          payload={{
            ...step6Payload,
            projectCode: currentProject.remoteId,
            language: getLanguageParamKey(),
          }}
        />
        <AccessoriesModal
          isOpen={isOpen.accessories}
          onClose={async () => {
            await getStep6Data(apiHash, step6Payload);
            closeModal('accessories');
          }}
          advanceStep={false}
          tray={trayId}
        />
        <PDFModal
          isOpen={!!pdfLink}
          onClose={() => setPdfLink('')}
          url={pdfLink}
        />
        <div className="recommended-products-container">
          <div
            className="recommended-products-toggle"
            onClick={toggleRecommended}
          >
            <strong>{t('project.details.others.also.bought')}</strong>
          </div>
          {isOpen.recommended && (
            <div>
              <div className="recommended-product">
                <img
                  src="https://www.technischeunie.nl/images/artikel/1170683.gif"
                  alt={'recommended-product-img'}
                  width="85px"
                />
                <div>
                  <div className="text-secondary">CSU08780200</div>
                  <div className="product-name">
                    ELECTRISCHE METAALSCHAAR KG/KB MES
                  </div>
                  <div>€ 340,79</div>
                  <button
                    className="btn-tertiary"
                    onClick={() => {
                      ReactGA.event({
                        category: 'CableTracker.recommendedProduct',
                        action: 'click',
                        label: 'add_to_cart',
                        // product_id: '',
                      });

                      data && addToCartViaElbridge(data);
                    }}
                  >
                    {t('project.review.add.to.cart')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>

      <div className="form-footer">
        <button
          onClick={() => {
            ReactGA.event({
              category: 'CableTracker.overview',
              action: 'click',
              label: 'previous_step',
            });
            setStep(previousStep);
          }}
          className="btn-secondary"
        >
          {t('global.previous')}
        </button>
        {showAddToCart ? (
          <button
            onClick={() => {
              ReactGA.event({
                category: 'CableTracker.overview',
                action: 'click',
                label: 'add_to_cart',
              });
              data && addToCartViaElbridge(data);
            }}
          >
            {t('project.review.add.to.cart')}
          </button>
        ) : (
          <button onClick={() => openModal('xlsx')}>
            {t('project.review.export.project.xlsx')}
          </button>
        )}
      </div>
    </div>
  );
};

export default Overview;
