import './styles.css';
import { useEffect, useState } from 'react';
import { useMyContext } from 'helpers';
import { Modal, ProductThumbnail } from 'shared/components';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import {
  Pendant as PendantIcon,
  Ceiling,
  Wall,
  Floor,
} from 'assets/icons/suspensions';
import PendantSteps from '../PendantSteps';
import WallSteps from '../WallSteps';
import CeilingSteps from '../CeilingSteps';

import type { Pendant, Suspension } from 'components/MultiStepForm/types';
import type { ModalProps } from 'shared/types';

const mountTypeIcons: Record<string, React.ReactNode> = {
  'Ceiling suspension': <Ceiling />,
  'Wall suspension': <Wall />,
  'Pendant suspension': <PendantIcon />,
  'Floor suspension': <Floor />,
};

interface SuspensionModalProps extends ModalProps {
  suspensions: Suspension[];
  pendants: Pendant[];
  requiredPieces: number;
  supportDistances: string[];
}

type SuspensionSteps = 1 | 2 | 3;

const SuspensionsModal = ({
  isOpen,
  onClose,
  suspensions,
  pendants,
  supportDistances,
}: SuspensionModalProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<SuspensionSteps>(1);
  const { setConfig, currentConfiguration } = useMyContext<3>();
  const {
    suspension,
    mountType,
    dutyType: selectedDutyType,
    suspensionObj,
  } = currentConfiguration.data;

  const mountTypes = suspensions.filter(
    (suspension, i, arr) =>
      arr.findIndex((s) => s.mountType === suspension.mountType) === i
  );

  useEffect(() => {
    !isOpen && setStep(1);
  }, [isOpen]);

  const modalTitles = {
    1: t('global.choose.suspension'),
    2: t('global.choose.suspension.type'),
    3: t('global.choose.bracket'),
  };

  const goForth = () => {
    ReactGA.event({
      category: 'CableTracker.suspensionsModal',
      action: 'next',
      label: 'step_' + (step + 1),
    });
    setStep((step) => (step + 1) as SuspensionSteps);
  };

  useEffect(() => {
    if (suspension && suspensionObj && !mountType && !selectedDutyType)
      setConfig(
        {
          mountType: suspensionObj.mountType,
          dutyType: suspensionObj.dutyType,
        },
        false
      );

  }, [mountType, suspension, selectedDutyType, suspensionObj, setConfig]);

  return (
    <Modal isOpen={isOpen} className="suspensions-modal">
      {step === 1 && (
        <>
          <Modal.Header onClose={onClose}>{modalTitles[1]}</Modal.Header>
          <Modal.Content>
            {mountTypes.map((type) => (
              <div
                key={`option-${type.cr}-${type.mountType}`}
                className="mount-type"
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.step1',
                    action: 'mount_type',
                    label: type.mountType,
                  });
                  setConfig({ mountType: type.mountType }, false);
                  goForth();
                }}
              >
                <p>{type.mountType}</p>
                <ProductThumbnail
                  img={mountTypeIcons[type.mountType]}
                  selected={
                    mountType === type.mountType ||
                    type.mountType === suspensionObj?.mountType
                  }
                />
              </div>
            ))}
            {pendants.length ? (
              <p>{t('tray.additional.modal.footer.msg')}</p>
            ) : null}
          </Modal.Content>
        </>
      )}
      {step === 2 && mountType === 'Wall suspension' && (
        <WallSteps
          supportDistances={supportDistances || []}
          suspensions={suspensions}
          onClose={onClose}
        />
      )}
      {step === 2 && mountType === 'Pendant suspension' && (
        <PendantSteps
          supportDistances={supportDistances || []}
          suspensions={suspensions}
          onClose={onClose}
          pendants={pendants}
        />
      )}
      {step === 2 && mountType === 'Ceiling suspension' && (
        <CeilingSteps
          supportDistances={supportDistances || []}
          suspensions={suspensions}
          onClose={onClose}
          pendants={pendants}
        />
      )}
    </Modal>
  );
};

export default SuspensionsModal;
